const initialState = {
  Key: "Value",
  token: sessionStorage.getItem("token") || null,
  path: sessionStorage.getItem("selectedRoute") || "/",
  modal: false,
  AdminModal: false,
  admin: false,
  selectedMember: null,
  navigation: [
    {
      name: "Home",
      path: "/home",
      logo: "/assets/images/icdrlogo.png",
    },
    {
      name: "About",
      path: "/about",
      logo: "/assets/images/icdrlogo.png",
    },
    {
      name: "Arbitration",
      path: "/arbitration",
      logo: "/assets/images/icdrlogo.png",
    },
    {
      name: "Mediation",
      path: "/mediation",
      logo: "/assets/images/icdrlogo.png",
    },
    // {
    //   name: "Articles",
    //   path: "/articles",
    // },
    {
      name: "ISCDR",
      path: "/iscdr",
      logo: "/assets/images/iscdrlogo.png",
    },
  ],
  validCode: sessionStorage.getItem("valid") || false,
};

const rootReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_TOKEN":
      return {
        ...state,
        token: action.payload,
      };
    case "SET_PATH":
      return {
        ...state,
        path: action.payload,
      };
    case "SET_MODAL":
      return {
        ...state,
        modal: action.payload,
      };
    case "SET_VALID_CODE":
      return {
        ...state,
        validCode: action.payload,
      };
    case "SET_ADMIN":
      return {
        ...state,
        admin: action.payload,
      };
    case "SET_ADMIN_MODAL":
      return {
        ...state,
        AdminModal: action.payload,
      };
    case "SET_SELECTED_MEMBER":
      return {
        ...state,
        selectedMember: action.payload,
      };
    default:
      return state;
  }
};

export default rootReducer;
