import React from "react";
import "swiper/css";
import "swiper/css/pagination";
import ImageSwiper from "../../components/Swiper/ImageSwiper";
import { aboutText } from "../../Utils/sample";

export default function Home() {
  const scrollTo = (selector: string) => {
    const element = document.querySelector(selector);
    element!.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      {" "}
      <ImageSwiper />
      <div className="site-section section-1" id="start">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 mb-md-4 mb-2">
              <img
                src="/assets/images/icdrlogo.png"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-lg-4">
              <div className="px-lg-3">
                <p className="dropcap">{aboutText}</p>
                <p>
                  Established to provide effective, efficient, and impartial
                  conflict resolution services on a global scale, the ICDR
                  caters to disputes transcending national borders.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <p>
                It operates with a commitment to neutrality, recognizing the
                diverse legal, cultural, and procedural backgrounds involved in
                international disputes.
              </p>

              {/* <!-- <blockquote className="blockquote">
              <p>We're committed to success &amp; genuine provider of all types of legal services</p>
            </blockquote> --> */}
            </div>
          </div>
        </div>
      </div>
      <div className="section-2 ">
        <div className="container ">
          <div className="row no-gutters align-items-stretch align-items-center">
            <h3 className="text-center p-3 d-flex flex-column">
              <div className="d-flex justify-content-center">
                <img
                  width="40"
                  height="40"
                  src="/assets/images/icdrlogo.png"
                  className="mb-1 mr-3"
                />
              </div>
              Some of the arbitrators, participants and doctors in The
              International Centre for Dispute Resolution "ICDR" have more than
              16 years of experience in arbitration, training, and education in
              more than 15 countries. They have more than 152 local and
              international participations. Training and educating more than
              20,000 people from more than 20 different nationalities.
            </h3>
          </div>
        </div>
      </div>
      <div className="site-section section-3">
        <div className="container">
          <div className="row justify-content-center mb-5">
            <div className="col-lg-6 section-title text-center">
              <h2>Services</h2>
              <p>
                These services are designed to address the unique challenges of
                international business and legal disputes, offering a viable
                alternative to traditional litigation.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="service-2 d-flex">
                <div className="service-2-icon mr-3">
                  <span className="flaticon-law"></span>
                </div>
                <div className="service-2-contents">
                  <h3>International Arbitration</h3>
                  <p>
                    Provides a structured process for resolving disputes outside
                    the courts, with decisions made by neutral arbitrators.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="service-2 d-flex">
                <div className="service-2-icon mr-3">
                  <span className="flaticon-balance"></span>
                </div>
                <div className="service-2-contents">
                  <h3>Mediation</h3>
                  <p>
                    Offers a more flexible, voluntary process where a mediator
                    assists parties in reaching a mutually acceptable
                    settlement.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="service-2 d-flex">
                <div className="service-2-icon mr-3">
                  <span className="flaticon-auction"></span>
                </div>
                <div className="service-2-contents">
                  <h3>Training and Education</h3>
                  <p>
                    Provides resources and training programs to educate about
                    international arbitration and mediation practices.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div className="service-2 d-flex">
                <div className="service-2-icon mr-3">
                  <span className="flaticon-auction"></span>
                </div>
                <div className="service-2-contents">
                  <h3>Administrative Services</h3>
                  <p>
                    Offers case management and administrative support for the
                    entire dispute resolution process.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="service-2 d-flex">
                <div className="service-2-icon mr-3">
                  <span className="flaticon-courthouse"></span>
                </div>
                <div className="service-2-contents">
                  <h3>Rules and Procedures</h3>
                  <p>
                    Develops and implements comprehensive rules and procedures
                    specifically designed for international dispute resolution.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
